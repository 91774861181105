const { makeStyles } = require("@material-ui/core");

export const Styles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        flexGrow: 1,
    },
    barLink: {
        border: '1px solid black',
        margin: '5px',
        backgroundColor: 'rgba(0,0,0,.5)'
    },
    link: {
        textDecoration: 'none',
        color: 'inherit'
    },
    splitPic: {
        width: '100%'
    },
    textContainer: {
        color: 'white',
        height: '100%',
        background: 'rgba(0,0,0,0)'
    },
    splitPane: {
        background: 'rgba(0, 0, 0, .5)',
        marginTop: '100px',
        color: 'white',
    },
    colorPrimary: {
        backgroundColor: '#051F20'
    },
    techButton: {
        background: '#89b4ad'
    },
    button: {

    }
}));
import React, { useState } from 'react'
import { Paper, Card, CardActionArea, CardMedia, Typography, CardContent, CardActions, Button, Grid } from '@material-ui/core'
import ProjectCard from './ProjectCard'
import { projects } from '../Utilities/Data'

function Projects(props){
    return (
        <Grid container alignItems='center' justify='center' spacing='8'>
            {projects.map(project => {
                return <ProjectCard project={project} />
            })}
        </Grid>
    )
}

export default Projects
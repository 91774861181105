import React from 'react'
import { Card, CardActionArea, CardMedia, Typography, Button, CardContent, CardActions, Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

function ProjectCard({ project }) {
    const history = useHistory()
    return (
        <Grid item>
            <Card style={{ maxWidth: 300 }}>
                <CardActionArea onClick={() => {
                    history.push(`/projects/${project.title.replace(/ /g, '_').toLowerCase()}`)
                }}>
                    <CardMedia style={{ height: 140 }}
                        image={project.image}
                    />
                    <CardContent>
                        <Typography variant='h5'>
                            {project.title}
                        </Typography>
                        <Typography variant="body2" component='p'>
                            {project.description}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <Button size='small' color='primary' href={project.git}>
                        View on Github
                        </Button>
                    <Button size='small' color='primary' href={project.deploy}>
                        View in action
                        </Button>
                </CardActions>
            </Card>
        </Grid>
    )
}
export default ProjectCard
export const skills = [
    {
        title: 'Java',
        description: '',
        notableProjects: [
            {
                title: 'QuestBot',
                link: '/projects/QuestBot'
            }
        ]
    }
]
export const projects = [
    {
        image: require('../img/questbot.png'),
        title: 'QuestBot',
        description: 'A discord bot built in Java, using Discord4J and LavaPlayer',
        git: 'https://github.com/allyjay317/QuestBot',
        deploy: '#',
        details: 'Ex dolor nulla sint duis eiusmod aliqua commodo nulla ad. Sit velit exercitation ad magna dolore incididunt ad consectetur aute pariatur do laborum. Aliqua minim non nulla velit do adipisicing. Ea proident veniam fugiat in amet. Laboris dolore tempor quis sit occaecat magna culpa sint officia esse consectetur ipsum est. Est cillum consectetur ea occaecat irure tempor nisi ipsum sint nisi veniam exercitation.',
        technologies: [
            {
                title: 'Java',
                link: 'https://www.java.com/en/'
            },
            {
                title: 'Sqlite',
                link: 'https://www.sqlite.org/index.html'
            },
            {
                title: 'Discord4J',
                link: 'https://github.com/Discord4J/Discord4J'
            },
            {
                title: 'LavaPlayer',
                link: 'https://github.com/sedmelluq/lavaplayer'
            },
        ]
    },
    {
        image: require('../img/ffxiv.png'),
        title: 'Final Fantasy Character Lookup',
        description: 'SPA React application that allows you to search for characters from the game Final Fantasy XIV.',
        git: 'https://github.com/allyjay317/React-Redux-App/tree/allison-usher',
        deploy: 'https://sleepy-joliot-9e176f.netlify.app/',
        pageInfo: {

        },
        details: 'Ex dolor nulla sint duis eiusmod aliqua commodo nulla ad. Sit velit exercitation ad magna dolore incididunt ad consectetur aute pariatur do laborum. Aliqua minim non nulla velit do adipisicing. Ea proident veniam fugiat in amet. Laboris dolore tempor quis sit occaecat magna culpa sint officia esse consectetur ipsum est. Est cillum consectetur ea occaecat irure tempor nisi ipsum sint nisi veniam exercitation.',
        technologies: [
            {
                title: 'React',
                link: 'https://reactjs.org/'
            },
            {
                title: 'XIVAPI',
                link: 'https://xivapi.com/'
            },
        ]
    },

]

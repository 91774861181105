import React, { useState, useEffect } from 'react'
import SplitPane from './SplitPane'
import { projects } from '../Utilities/Data'
import { useParams } from 'react-router-dom';
import { Typography, ButtonGroup, Button, Grid } from '@material-ui/core';
import { Styles } from './Styles';

function ProjectPage() {
    const params = useParams();
    const [project, setProject] = useState(projects.find(element => titleMatch(element)))


    function titleMatch(element) {
        debugger
        let noWhite = element.title.replace(/ /g, '_')
        return noWhite.toLowerCase() === params.projectName.toLowerCase()
    }

    useEffect(() => {
        setProject(projects.find(element => titleMatch(element)))
    }, [params.projectName])

    const classes = Styles()
    return (
        <SplitPane picSize='5' img={project.image}>
            <Grid container xs direction='column' justify='space-between' spacing='4'>
                <Grid item>
                    <Typography variant='h2'>
                        {project.title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant='body1' component='p'>
                        {project.details}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant='h5' component='p'>
                        Technologies Used
                    </Typography>
                    <ButtonGroup variant='contained' color='primary'>
                        {project.technologies.map((tech) => {
                            return <Button href={tech.link} className={classes.techButton}>{tech.title}</Button>
                        })}
                    </ButtonGroup>
                </Grid>
                <Grid item>
                    <ButtonGroup variant='contained' color='primary' className={classes.techButton}>
                        <Button href={project.git} >
                            View on Github
                        </Button>
                        <Button href={project.deploy}>
                            View Deployed
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
        </SplitPane>
    )
}

export default ProjectPage
import React from 'react'
import { Accordion, AccordionSummary, Typography, AccordionDetails, AccordionActions, Button } from '@material-ui/core'

function SkillAccordian({ skill }){
    return(
    <Accordion square>
        <AccordionSummary>
            <Typography>
                {skill.title}
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
            {skill.description}
        </AccordionDetails>
        <AccordionActions>
            {skill.notableProjects.map((project) =>{
                return <Button size='small' color='primary' href={project.link}>{project.title}</Button>
            })}
        </AccordionActions>
    </Accordion>)
}

export default SkillAccordian
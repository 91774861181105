import React from 'react'
import { Typography, Paper } from '@material-ui/core'
import { Styles } from './Styles'
import { skills } from '../Utilities/Data'
import SkillAccordian from './SkillAccordian'

function Main() {
    const classes = Styles();
    return (
        <Paper elevation={3} class={classes.textContainer}>
            <Typography variant='h1' className={classes.title}>
                Hi, I'm Allison
            </Typography>
            <Typography>
                I'm a student at Lambda school, studying to become a Full Stack Developer. There, I'm learning to use HTML, Javascript, CSS and React.
            </Typography>
            <Typography>
                Outside of Lambda, I studied for four years at Eastern Washington University in pursuit of a BS in Computer Science, learning languages such as Java, C and C#
            </Typography>
            <Typography variant='h5'>
                Skills
            </Typography>
            {skills.map((skill) => {
                return <SkillAccordian skill={skill} />
            })}
        </Paper>
    )
}

export default Main
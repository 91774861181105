import React, { useState, useEffect } from 'react';
import './css/index.css';
import Header from './components/Header';
import Main from './components/Main';
import background from './img/background.JPG'
import SplitPane from './components/SplitPane';
import { Route } from 'react-router-dom';
import Projects from './components/Projects';
import Contact from './components/Contact';
import ProjectPage from './components/ProjectPage';

function App() {
  const bkgSize = {
    position: 'fixed',
    zIndex: '-1',
    minWidth: '100vw',
    minHeight: '100vh'
  }

  useEffect(() => {

  }, [])

  return (

    <div className="App">
      <img id='bkgImg' src={background} style={bkgSize} alt='Background' />

      <Header />
      <Route exact path='/' >
        <SplitPane split='row' picSize='7' img={require('./img/portfolio_pic.JPG')}>
          <Main />
        </SplitPane>
      </Route>
      <Route exact path='/projects'>
        <SplitPane split='column' title='Projects'>
          <Projects />
        </SplitPane>
      </Route>
      <Route path='/projects/:projectName'>
        <ProjectPage />
      </Route>
      <Route path='/contact'>
        <SplitPane split='column' picSize='7' title='Contact Me'>
          <Contact />
        </SplitPane>
      </Route>
    </div>
  );
}

export default App;

import React from 'react'
import { Container, Grid, Typography } from '@material-ui/core';
import { Styles } from './Styles'

function SplitPane(props){
    const classes = Styles();
    return(
    <Container className={classes.splitPane}>
        <Grid container justify='center' alignItems='center' direction={props.split} xs spacing='4'>
        {props.img !== undefined ? (
            <Grid item xs={props.picSize}>
                <img src={props.img} className={classes.splitPic} />
            </Grid>
        ) : (
            <Typography variant='h1'>
                {props.title}
            </Typography>
        )}
            <Grid item xs>
                {props.children}
            </Grid>
        </Grid>
    </Container>
    )
}

export default SplitPane